import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
   
    { title: 'Inicio', url: '/home', icon: 'home' },

    //{ title: 'Mapa', url: '/mapagral', icon: 'location' },
    { title: 'Mapas del SIV', url: '/explora', icon: 'location' },
    { title: 'Red de Actores', url: '/comunidad', icon: 'git-merge' },
    { title: 'Tipos de SbN', url: '/descubre', icon: 'list' },
    { title: 'Especies SbN', url: '/protege', icon: 'search' },
   
    //{ title: 'Educar', url: '/conoce', icon: 'easel' },
    //{ title: 'Eventos', url: '/noticias', icon: 'calendar' },
    { title: 'Enlaces', url: '/enlaces', icon: 'link' },
    { title: 'Bibliografía', url: '/chat', icon: 'library' },
    
    //{ title: 'Sesión', url: '/sesion', icon: 'finger-print' },
    { title: 'Asistente IA', url: '/asistenteia', icon: 'laptop' },
    { title: 'Ingresar registro', url: '/reporta', icon: 'create' },

    { title: 'About', url: '/acerca', icon: 'help' }
    
  ];
 
  constructor() {}
}
